import React from 'react';
import { useDispatch } from 'react-redux';
import { useUser, useBuilderType } from 'hooks';
import { PaidAccessFragment } from 'lib/fragments/UserFragments';
import { trackEvent } from 'Tracking/Actions';
import { executeUnifiedChatLogoutCallback } from 'lib/chat/executeUnifiedChatLogoutCallback';

const NonFreemiumMenu = () => {
  const dispatch = useDispatch();
  const { data } = useUser('PaidAccessFragment', PaidAccessFragment);
  const { isFreewrite, isVisualStatic } = useBuilderType();
  const hasPaidAccess = data?.user?.hasPaidAccess ?? false;

  const getHelpUrl = () => {
    if (isFreewrite) {
      return 'https://help.formswift.com/knowledge/pdf-editor-help';
    }
    if (isVisualStatic) {
      return 'https://help.formswift.com/knowledge/tax-documents-e-file-help';
    }
    return 'https://help.formswift.com/knowledge';
  };

  const trackMyDocumentsClick = () => {
    dispatch(trackEvent('MyDocuments', 'Top Nav'));
  };

  const trackHelpClick = () => {
    dispatch(trackEvent('Help', 'Top Nav'));
  };

  const trackLogOut = () => {
    dispatch(trackEvent('Logout', 'Top Nav'));
  };

  const handleLogOut = () => {
    trackLogOut();
    executeUnifiedChatLogoutCallback();
  };

  return (
    <div>
      <a href="/my-documents" onClick={trackMyDocumentsClick}>
        My Documents
      </a>
      <span>|</span>
      {hasPaidAccess && (
        <>
          <a href={getHelpUrl()} onClick={trackHelpClick}>
            Help
          </a>
          <span>|</span>
        </>
      )}
      <a href="/_api/logout.php" onClick={handleLogOut}>
        Log out
      </a>
    </div>
  );
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default NonFreemiumMenu;
