import React from 'react';
import { useDispatch } from 'react-redux';
import { useUser, useBuilderType, useWindowSize } from 'hooks';
import { IconButton } from '@dropbox/dig-components/buttons';
import { Tooltip } from '@dropbox/dig-components/tooltips';
import { Link } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { HelpLine, HomeLine, SignOutLine } from '@dropbox/dig-icons/assets';
import { PaidAccessFragment } from 'lib/fragments/UserFragments';
import { trackEvent } from 'Tracking/Actions';
import { FreemiumMenu } from './FreemiumMenu/FreemiumMenu.dig';

export const NavBar = ({
  isFreemium,
  userEmail,
}: {
  isFreemium: boolean;
  userEmail: string;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { isFreewrite, isVisualStatic } = useBuilderType();
  const { data } = useUser('PaidAccessFragment', PaidAccessFragment);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const hasPaidAccess = data?.user?.hasPaidAccess ?? false;
  const { isMobile } = useWindowSize();

  const getHelpUrl = () => {
    if (isFreewrite) {
      return 'https://help.formswift.com/knowledge/pdf-editor-help';
    }
    if (isVisualStatic) {
      return 'https://help.formswift.com/knowledge/tax-documents-e-file-help';
    }
    return 'https://help.formswift.com/knowledge';
  };

  const trackMyDocumentsClick = () => {
    dispatch(trackEvent('MyDocuments', 'Top Nav'));
  };

  const trackHelpClick = () => {
    dispatch(trackEvent('Help', 'Top Nav'));
  };

  const trackLogOut = () => {
    dispatch(trackEvent('Logout', 'Top Nav'));
  };

  return (
    <>
      {isMobile ? (
        <IconButton
          href="/my-documents"
          onClick={trackMyDocumentsClick}
          variant="borderless"
        >
          <UIIcon src={HomeLine} />
        </IconButton>
      ) : (
        <div className="my-documents-link">
          <Link
            hasNoUnderline
            href="/my-documents"
            onClick={trackMyDocumentsClick}
            variant="monochromatic"
          >
            <UIIcon src={HomeLine} />
            My Documents
          </Link>
        </div>
      )}
      {isFreemium && <FreemiumMenu userEmail={userEmail} />}
      {(hasPaidAccess || isFreemium) && (
        <IconButton
          data-testid="help-button"
          href={getHelpUrl()}
          onClick={trackHelpClick}
          variant="borderless"
        >
          <UIIcon src={HelpLine} />
        </IconButton>
      )}
      <Tooltip id="logoutTooltip" placement="bottom" title="Log out">
        <IconButton
          data-testid="logout-button"
          href="/_api/logout.php"
          onClick={trackLogOut}
          variant="borderless"
        >
          <UIIcon src={SignOutLine} />
        </IconButton>
      </Tooltip>
    </>
  );
};
