import React from 'react';
import { Banner } from 'DesignSystem/Banner';
import { ProductContent } from 'DesignSystem/Typography';
import { SoloLink } from 'DesignSystem/Links';
import { ElementWithTooltip } from 'DesignSystem/Tooltips';
import { HelpIcon } from 'DesignSystem/Icons';
import { useWindowSize } from 'hooks';
import useTrack from 'lib/tracking/useTrack';
import styles from './index.module.scss';
import { hideBannerFromUser } from '../Utils';

export const taxFormBannerConfig = {
  BANNER_ID: 'taxFormBanner',
  SHOW_BANNER: true,
  TAX_YEAR: 2023,
};

export const TaxFormBanner = (): JSX.Element => {
  const { isMaxWidthSm, isMobile } = useWindowSize();
  const track = useTrack();

  const { BANNER_ID, TAX_YEAR } = taxFormBannerConfig;

  const handleCloseClick = () => {
    hideBannerFromUser(BANNER_ID);
  };

  const handleTrackEvent = (trackingCategory: string) => () => {
    void track({
      action: 'My Documents',
      category: `${trackingCategory} | E-file Tax Banner`,
    });
  };

  const iconButton = (
    <div className={styles['tax-form-banner-tooltip-icon']}>
      <HelpIcon dataTestid="tax-form-banner-tooltip" />
    </div>
  );

  return (
    <Banner
      className={styles['tax-form-banner']}
      onCloseClick={handleCloseClick}
    >
      <ProductContent className={styles['tax-form-banner-message']}>
        Fill out your {TAX_YEAR} Tax Forms
      </ProductContent>
      {isMobile && <div className={styles['tax-form-banner-line-break']} />}
      <SoloLink
        className={styles['tax-form-banner-link']}
        dataTestid="tax-form-banner-w2-link"
        href={`/builder/tax/w2-efile-${TAX_YEAR}`}
        onClick={handleTrackEvent(`Create ${TAX_YEAR} W2`)}
      >
        W2
      </SoloLink>
      <div className={styles['tax-form-banner-divider']} />
      <SoloLink
        className={styles['tax-form-banner-link']}
        dataTestid="tax-form-banner-1099-misc-link"
        href={`/builder/tax/1099-misc-efile-${TAX_YEAR}`}
        onClick={handleTrackEvent(`Create ${TAX_YEAR} 1099-MISC`)}
      >
        1099-MISC
      </SoloLink>
      <div className={styles['tax-form-banner-divider']} />
      <SoloLink
        className={styles['tax-form-banner-link']}
        dataTestid="tax-form-banner-1099-nec-link"
        href={`/builder/tax/1099-nec-efile-${TAX_YEAR}`}
        onClick={handleTrackEvent(`Create ${TAX_YEAR} 1099-NEC`)}
      >
        1099-NEC
      </SoloLink>
      {!isMobile && (
        <ElementWithTooltip
          displayEvent="hover"
          size="xs"
          targetElement={iconButton}
          tooltipId="tax-form-banner-tooltip"
        >
          For the tax year 2020 and onwards, use Form 1099-NEC to report
          nonemployee compensation. And only use Form 1099-MISC for non-wage
          payments.
        </ElementWithTooltip>
      )}
      <div className={styles['tax-form-banner-divider']} />
      <SoloLink
        className={styles['tax-form-banner-link']}
        dataTestid="tax-form-banner-all-forms-link"
        href={`/document-library?search=${TAX_YEAR}%20tax`}
        onClick={handleTrackEvent(`See all ${TAX_YEAR} Forms`)}
      >
        {isMaxWidthSm ? 'All' : 'All Tax Forms'}
      </SoloLink>
    </Banner>
  );
};
