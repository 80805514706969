import * as Types from '../../schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAccountMutationVariables = Types.Exact<{
  actions: Array<Types.UpdateAccountAction> | Types.UpdateAccountAction;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: {
    __typename?: 'UpdateAccountResponse';
    success?: {
      __typename?: 'UpdateAccountResponseSuccess';
      ok?: boolean | null;
      message?: string | null;
      user?: {
        __typename?: 'User';
        id: string;
        email?: string | null;
        name?: string | null;
        vatId?: string | null;
      } | null;
    } | null;
    error?:
      | { __typename?: 'UpdateEmailErrorType'; message: string }
      | { __typename?: 'UpdateNameErrorType'; message: string }
      | { __typename?: 'UpdatePasswordErrorType'; message: string }
      | { __typename?: 'UpdateVatIdErrorType' }
      | null;
  };
};

export type InviteTeammateMutationVariables = Types.Exact<{
  inviteTeammateInput: Types.InviteTeammateInput;
}>;

export type InviteTeammateMutation = {
  __typename?: 'Mutation';
  inviteTeammate: {
    __typename?: 'InviteTeammateResponse';
    error?: {
      __typename?: 'InviteTeammateResponseError';
      message: string;
    } | null;
    success?: {
      __typename?: 'InviteTeammateResponseSuccess';
      ok?: boolean | null;
      message?: string | null;
      user?: {
        __typename?: 'User';
        id: string;
        organization?: {
          __typename?: 'Organization';
          id?: number | null;
          organizationMemberDetails?: {
            __typename?: 'OrganizationMemberDetails';
            numberOfSeatsRemaining: number;
            numberOfSeatsTotal: number;
            invitations?: Array<{
              __typename?: 'OrganizationInvitations';
              email: string;
              expiresOn?: string | null;
              status: Types.InvitationStatus;
            } | null> | null;
          } | null;
        } | null;
      } | null;
    } | null;
  };
};

export type ReactivateCustomerMutationVariables = Types.Exact<{
  planHandle: Types.Scalars['String'];
}>;

export type ReactivateCustomerMutation = {
  __typename?: 'Mutation';
  reactivateCustomer?: {
    __typename?: 'ReactivateCustomer';
    ok?: boolean | null;
    user?: {
      __typename?: 'User';
      id: string;
      hasPaidAccess?: boolean | null;
      isCancelled?: boolean | null;
      isCurrentSubscriber?: boolean | null;
      planId?: string | null;
      plan?: { __typename?: 'Plan'; planDescription?: string | null } | null;
      subscription?: {
        __typename?: 'Subscription';
        currency?: string | null;
        currentPeriodEndsAt?: string | null;
        currentState?: string | null;
        interval?: number | null;
        intervalUnit?: string | null;
        subscriptionPrice?: number | null;
        taxIncluded?: boolean | null;
        product?: {
          __typename?: 'Product';
          pricePoint?: {
            __typename?: 'PricePoint';
            priceInCents?: number | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CancelUserSubscriptionMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CancelUserSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelSubscription?: {
    __typename?: 'CancelSubscription';
    ok?: boolean | null;
    user?: {
      __typename?: 'User';
      id: string;
      isCancelled?: boolean | null;
      isCurrentSubscriber?: boolean | null;
    } | null;
  } | null;
};

export type CreateCancellationEmailReminderMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CreateCancellationEmailReminderMutation = {
  __typename?: 'Mutation';
  createCancellationEmailReminder?: {
    __typename?: 'CreateCancellationEmailReminder';
    emailReminder?:
      | { __typename?: 'ChargifyRetrievalGqlError'; message: string }
      | { __typename?: 'EmailReminder'; senderUserId?: number | null }
      | { __typename?: 'MultipleUnsentRemindersGqlError'; message: string }
      | null;
  } | null;
};

export type MigrateCustomerToPlanMutationVariables = Types.Exact<{
  planHandle: Types.Scalars['String'];
}>;

export type MigrateCustomerToPlanMutation = {
  __typename?: 'Mutation';
  migrateCustomerToPlan?: {
    __typename?: 'MigrateCustomerToPlan';
    ok?: boolean | null;
  } | null;
};

export type RemoveFromOrganizationMutationVariables = Types.Exact<{
  action: Types.RemoveUserFromOrganizationAction;
  removeUserInput?: Types.InputMaybe<Types.RemoveUserInput>;
}>;

export type RemoveFromOrganizationMutation = {
  __typename?: 'Mutation';
  removeFromOrganization: {
    __typename?: 'RemoveUserFromOrganizationResponse';
    success?: {
      __typename?: 'RemoveUserFromOrganizationResponseSuccess';
      ok?: boolean | null;
      message?: string | null;
      user?: {
        __typename?: 'User';
        id: string;
        organization?: {
          __typename?: 'Organization';
          isOrganizationAdmin: boolean;
          id?: number | null;
          organizationMemberDetails?: {
            __typename?: 'OrganizationMemberDetails';
            numberOfSeatsRemaining: number;
            numberOfSeatsTotal: number;
            invitations?: Array<{
              __typename?: 'OrganizationInvitations';
              email: string;
              expiresOn?: string | null;
              status: Types.InvitationStatus;
            } | null> | null;
          } | null;
        } | null;
      } | null;
    } | null;
    error?: {
      __typename?: 'RemoveUserFromOrganizationResponseError';
      message: string;
    } | null;
  };
};

export type RevokeOrganizationInviteMutationVariables = Types.Exact<{
  teammateEmail?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type RevokeOrganizationInviteMutation = {
  __typename?: 'Mutation';
  revokeOrganizationInvite: {
    __typename?: 'RevokeOrganizationInviteResponse';
    error?: {
      __typename?: 'RevokeOrganizationInviteResponseError';
      message: string;
    } | null;
    success?: {
      __typename?: 'RevokeOrganizationInviteResponseSuccess';
      message?: string | null;
      ok?: boolean | null;
      user?: {
        __typename?: 'User';
        id: string;
        organization?: {
          __typename?: 'Organization';
          isOrganizationAdmin: boolean;
          id?: number | null;
          organizationMemberDetails?: {
            __typename?: 'OrganizationMemberDetails';
            numberOfSeatsRemaining: number;
            numberOfSeatsTotal: number;
            invitations?: Array<{
              __typename?: 'OrganizationInvitations';
              email: string;
              expiresOn?: string | null;
              status: Types.InvitationStatus;
            } | null> | null;
          } | null;
        } | null;
      } | null;
    } | null;
  };
};

export const UpdateAccountDocument = gql`
  mutation UpdateAccount($actions: [UpdateAccountAction!]!) {
    updateAccount(actions: $actions) {
      success {
        ok
        message
        user {
          id
          email
          name
          vatId
        }
      }
      error {
        ... on UpdateEmailErrorType {
          message
        }
        ... on UpdateNameErrorType {
          message
        }
        ... on UpdatePasswordErrorType {
          message
        }
      }
    }
  }
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      actions: // value for 'actions'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<
  typeof useUpdateAccountMutation
>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const InviteTeammateDocument = gql`
  mutation InviteTeammate($inviteTeammateInput: InviteTeammateInput!) {
    inviteTeammate(inviteTeammateInput: $inviteTeammateInput) {
      error {
        message
      }
      success {
        ok
        message
        user {
          id
          organization {
            id
            organizationMemberDetails {
              invitations {
                email
                expiresOn
                status
              }
              numberOfSeatsRemaining
              numberOfSeatsTotal
            }
          }
        }
      }
    }
  }
`;
export type InviteTeammateMutationFn = Apollo.MutationFunction<
  InviteTeammateMutation,
  InviteTeammateMutationVariables
>;

/**
 * __useInviteTeammateMutation__
 *
 * To run a mutation, you first call `useInviteTeammateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteTeammateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteTeammateMutation, { data, loading, error }] = useInviteTeammateMutation({
 *   variables: {
 *      inviteTeammateInput: // value for 'inviteTeammateInput'
 *   },
 * });
 */
export function useInviteTeammateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteTeammateMutation,
    InviteTeammateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteTeammateMutation,
    InviteTeammateMutationVariables
  >(InviteTeammateDocument, options);
}
export type InviteTeammateMutationHookResult = ReturnType<
  typeof useInviteTeammateMutation
>;
export type InviteTeammateMutationResult = Apollo.MutationResult<InviteTeammateMutation>;
export type InviteTeammateMutationOptions = Apollo.BaseMutationOptions<
  InviteTeammateMutation,
  InviteTeammateMutationVariables
>;
export const ReactivateCustomerDocument = gql`
  mutation ReactivateCustomer($planHandle: String!) {
    reactivateCustomer(planHandle: $planHandle) {
      ok
      user {
        id
        hasPaidAccess
        isCancelled
        isCurrentSubscriber
        plan {
          planDescription
        }
        planId
        subscription {
          currency
          currentPeriodEndsAt
          product {
            pricePoint {
              priceInCents
            }
          }
          currentState
          interval
          intervalUnit
          subscriptionPrice
          taxIncluded
        }
      }
    }
  }
`;
export type ReactivateCustomerMutationFn = Apollo.MutationFunction<
  ReactivateCustomerMutation,
  ReactivateCustomerMutationVariables
>;

/**
 * __useReactivateCustomerMutation__
 *
 * To run a mutation, you first call `useReactivateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateCustomerMutation, { data, loading, error }] = useReactivateCustomerMutation({
 *   variables: {
 *      planHandle: // value for 'planHandle'
 *   },
 * });
 */
export function useReactivateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactivateCustomerMutation,
    ReactivateCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReactivateCustomerMutation,
    ReactivateCustomerMutationVariables
  >(ReactivateCustomerDocument, options);
}
export type ReactivateCustomerMutationHookResult = ReturnType<
  typeof useReactivateCustomerMutation
>;
export type ReactivateCustomerMutationResult = Apollo.MutationResult<ReactivateCustomerMutation>;
export type ReactivateCustomerMutationOptions = Apollo.BaseMutationOptions<
  ReactivateCustomerMutation,
  ReactivateCustomerMutationVariables
>;
export const CancelUserSubscriptionDocument = gql`
  mutation CancelUserSubscription {
    cancelSubscription {
      ok
      user {
        id
        isCancelled
        isCurrentSubscriber
      }
    }
  }
`;
export type CancelUserSubscriptionMutationFn = Apollo.MutationFunction<
  CancelUserSubscriptionMutation,
  CancelUserSubscriptionMutationVariables
>;

/**
 * __useCancelUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUserSubscriptionMutation, { data, loading, error }] = useCancelUserSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelUserSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelUserSubscriptionMutation,
    CancelUserSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelUserSubscriptionMutation,
    CancelUserSubscriptionMutationVariables
  >(CancelUserSubscriptionDocument, options);
}
export type CancelUserSubscriptionMutationHookResult = ReturnType<
  typeof useCancelUserSubscriptionMutation
>;
export type CancelUserSubscriptionMutationResult = Apollo.MutationResult<CancelUserSubscriptionMutation>;
export type CancelUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelUserSubscriptionMutation,
  CancelUserSubscriptionMutationVariables
>;
export const CreateCancellationEmailReminderDocument = gql`
  mutation createCancellationEmailReminder {
    createCancellationEmailReminder {
      emailReminder {
        ... on EmailReminder {
          senderUserId
        }
        ... on GqlErrorInterface {
          message
        }
      }
    }
  }
`;
export type CreateCancellationEmailReminderMutationFn = Apollo.MutationFunction<
  CreateCancellationEmailReminderMutation,
  CreateCancellationEmailReminderMutationVariables
>;

/**
 * __useCreateCancellationEmailReminderMutation__
 *
 * To run a mutation, you first call `useCreateCancellationEmailReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCancellationEmailReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCancellationEmailReminderMutation, { data, loading, error }] = useCreateCancellationEmailReminderMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCancellationEmailReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCancellationEmailReminderMutation,
    CreateCancellationEmailReminderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCancellationEmailReminderMutation,
    CreateCancellationEmailReminderMutationVariables
  >(CreateCancellationEmailReminderDocument, options);
}
export type CreateCancellationEmailReminderMutationHookResult = ReturnType<
  typeof useCreateCancellationEmailReminderMutation
>;
export type CreateCancellationEmailReminderMutationResult = Apollo.MutationResult<CreateCancellationEmailReminderMutation>;
export type CreateCancellationEmailReminderMutationOptions = Apollo.BaseMutationOptions<
  CreateCancellationEmailReminderMutation,
  CreateCancellationEmailReminderMutationVariables
>;
export const MigrateCustomerToPlanDocument = gql`
  mutation MigrateCustomerToPlan($planHandle: String!) {
    migrateCustomerToPlan(planHandle: $planHandle) {
      ok
    }
  }
`;
export type MigrateCustomerToPlanMutationFn = Apollo.MutationFunction<
  MigrateCustomerToPlanMutation,
  MigrateCustomerToPlanMutationVariables
>;

/**
 * __useMigrateCustomerToPlanMutation__
 *
 * To run a mutation, you first call `useMigrateCustomerToPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateCustomerToPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateCustomerToPlanMutation, { data, loading, error }] = useMigrateCustomerToPlanMutation({
 *   variables: {
 *      planHandle: // value for 'planHandle'
 *   },
 * });
 */
export function useMigrateCustomerToPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MigrateCustomerToPlanMutation,
    MigrateCustomerToPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MigrateCustomerToPlanMutation,
    MigrateCustomerToPlanMutationVariables
  >(MigrateCustomerToPlanDocument, options);
}
export type MigrateCustomerToPlanMutationHookResult = ReturnType<
  typeof useMigrateCustomerToPlanMutation
>;
export type MigrateCustomerToPlanMutationResult = Apollo.MutationResult<MigrateCustomerToPlanMutation>;
export type MigrateCustomerToPlanMutationOptions = Apollo.BaseMutationOptions<
  MigrateCustomerToPlanMutation,
  MigrateCustomerToPlanMutationVariables
>;
export const RemoveFromOrganizationDocument = gql`
  mutation RemoveFromOrganization(
    $action: RemoveUserFromOrganizationAction!
    $removeUserInput: RemoveUserInput
  ) {
    removeFromOrganization(action: $action, removeUserInput: $removeUserInput) {
      success {
        ok
        message
        user {
          id
          organization {
            isOrganizationAdmin
            id
            organizationMemberDetails {
              invitations {
                email
                expiresOn
                status
              }
              numberOfSeatsRemaining
              numberOfSeatsTotal
            }
          }
        }
      }
      error {
        message
      }
    }
  }
`;
export type RemoveFromOrganizationMutationFn = Apollo.MutationFunction<
  RemoveFromOrganizationMutation,
  RemoveFromOrganizationMutationVariables
>;

/**
 * __useRemoveFromOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromOrganizationMutation, { data, loading, error }] = useRemoveFromOrganizationMutation({
 *   variables: {
 *      action: // value for 'action'
 *      removeUserInput: // value for 'removeUserInput'
 *   },
 * });
 */
export function useRemoveFromOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFromOrganizationMutation,
    RemoveFromOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFromOrganizationMutation,
    RemoveFromOrganizationMutationVariables
  >(RemoveFromOrganizationDocument, options);
}
export type RemoveFromOrganizationMutationHookResult = ReturnType<
  typeof useRemoveFromOrganizationMutation
>;
export type RemoveFromOrganizationMutationResult = Apollo.MutationResult<RemoveFromOrganizationMutation>;
export type RemoveFromOrganizationMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromOrganizationMutation,
  RemoveFromOrganizationMutationVariables
>;
export const RevokeOrganizationInviteDocument = gql`
  mutation RevokeOrganizationInvite($teammateEmail: String) {
    revokeOrganizationInvite(teammateEmail: $teammateEmail) {
      error {
        message
      }
      success {
        message
        ok
        user {
          id
          organization {
            isOrganizationAdmin
            id
            organizationMemberDetails {
              invitations {
                email
                expiresOn
                status
              }
              numberOfSeatsRemaining
              numberOfSeatsTotal
            }
          }
        }
      }
    }
  }
`;
export type RevokeOrganizationInviteMutationFn = Apollo.MutationFunction<
  RevokeOrganizationInviteMutation,
  RevokeOrganizationInviteMutationVariables
>;

/**
 * __useRevokeOrganizationInviteMutation__
 *
 * To run a mutation, you first call `useRevokeOrganizationInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeOrganizationInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeOrganizationInviteMutation, { data, loading, error }] = useRevokeOrganizationInviteMutation({
 *   variables: {
 *      teammateEmail: // value for 'teammateEmail'
 *   },
 * });
 */
export function useRevokeOrganizationInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeOrganizationInviteMutation,
    RevokeOrganizationInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeOrganizationInviteMutation,
    RevokeOrganizationInviteMutationVariables
  >(RevokeOrganizationInviteDocument, options);
}
export type RevokeOrganizationInviteMutationHookResult = ReturnType<
  typeof useRevokeOrganizationInviteMutation
>;
export type RevokeOrganizationInviteMutationResult = Apollo.MutationResult<RevokeOrganizationInviteMutation>;
export type RevokeOrganizationInviteMutationOptions = Apollo.BaseMutationOptions<
  RevokeOrganizationInviteMutation,
  RevokeOrganizationInviteMutationVariables
>;
